function myFunction() {
  var x = document.getElementById("myTopnav");
  if (x.className === "topnav") {
    x.className += " responsive";
  } else {
    x.className = "topnav";
  }
}

jQuery(function ($) {
  var $myGroup = $('.makeToggle');
  $('[data-toggle="collapse"]', $myGroup).click(function () {
    $('.navigation-submenu', $myGroup).not(this).removeClass('show');
    $('[data-toggle="collapse"]', $myGroup).not(this).attr('aria-expanded', 'false');
  });
});